import functions from '../functions';
import pagination from '../components/pagination';
import listResults from '../components/listResults';

$(() => {
    list.init();
})

const list = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryId: null,
        sortBy: null,
        objectName: null,
    },
    mainFields: ['dateFrom', 'dateTo', 'destination', 'objectTypeId', 'persons', 'personsInObject', 'sortBy', '_page'],
    init() {

        this.$form = $('.search-form');
        this.form = this.$form[0];

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0];

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        if(! this.form) {
            return;
        }

    },
    getDataForUrl(params) {
        if (params._page === 1) params._page = null;
        params = functions.getDataForUrl(params);
        return params;
    },
    setUrlData(params) {
        params = list.getDataForUrl(params);
        functions.setUrlData(params, true, true);
    },
    getParams(params, defaults = {}, filterParams = {}) {
        params = $.extend({}, defaults, filterParams, functions.getUrlData(true));
        this.splitCalendarDates(params.dates);
        this.setDestination(params.destination);

        return params;
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    setDestination(destination) {

        if(destination) {
            const destinations = destination.split(',');

            let countryIds = [],
                regionIds = [],
                rivieraIds = [],
                placeIds = [];

            destinations.forEach(destination => {
                const dest = destination.split(':');

                this.params.countryId = dest[0] ? this.getDestinationIds(dest[0], countryIds) : this.defaults.countryId;
                this.params.regionId = dest[1] ? this.getDestinationIds(dest[1], regionIds) : null;
                this.params.rivieraId = dest[2] ? this.getDestinationIds(dest[2], rivieraIds) : null;
                this.params.placeId = dest[3] ? this.getDestinationIds(dest[3], placeIds) : null;
            });
        } else {
            this.params.countryId = this.defaults.countryId;
            this.params.regionId = null;
            this.params.rivieraId = null;
            this.params.placeId = null;
        }
    },
    getDestinationIds(destination, array) {
        if(destination && $.inArray(destination, array)) {
            array.push(destination);
        }
        return array.join();
    },
    find() {

        this.setLoadingMode();

        return $.get('/services/list/', this.query);

    },
    findDone(response) {
        response = JSON.parse(response);

        this.unsetLoadingMode();

        this.total = response.total;

        this.$listItems.html(response.html);
        if (this.total != 0) {

            if($('.paginate')[0]) {
                $('.paginate').html(pagination({
                    currentPage: this.params._page,
                    total: this.total,
                    perPage: this.defaults.perPage,
                }));
            }
        }

        listResults.getResults(this.total, this.params._page || 1, this.defaults.perPage);

        this.initComponents();

    },
    paginate(page) {
        if (page != this.params._page) {
            this.params._page = page;
        }

        functions.setUrlData(this.params, true);
    },
    setLoadingMode() {
        if(! this.loading) {
            this.loading = true;

            // form submit btn
            (this.$form).find('button').prop('disabled', true);

            if ( ! $('.spinner').length) {
                $('<div class="spinner d-none"><div class="spinner-border text-secondary" role="status" style="width: 4rem; height: 4rem;"><span class="sr-only">Loading...</span></div></div>').insertBefore('.list-items');
            }

            $('.spinner').removeClass('d-none');
            $('.spinner').addClass('d-flex justify-content-center');
            this.$listItems.addClass('d-none');
            $('.paginate').addClass('d-none');
        }
    },
    unsetLoadingMode() {
        if(this.loading) {
            this.loading = false;

            // form submit btn
            (this.$form).find('button').prop('disabled', false);

            $('.spinner').removeClass('d-flex justify-content-center');
            $('.spinner').addClass('d-none');
            this.$listItems.removeClass('d-none');
            $('.paginate').removeClass('d-none');

            //functions.scrollToElement(this.$listItems, 100);
        }
    },
    initComponents() {

        // register paginate
        $('[data-page]').on('click', e => {
            e.preventDefault();
            this.paginate(+$(e.currentTarget).attr('data-page'));
        });

    },
};

export {
    list,
};
