import functions from '../functions';
import { list } from './list';

$(() => {
    listTravel.init();
});

const listTravel = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        arrivalDateFrom: '',
        destination: '',
        countryId: '',
        objectGroupId: 6,
        hasArrival: 1,
        hasDiscount: null,
        tagCategoryId: null,
        difficulty: null,
    },
    params: {},
    isHome: false,
    $listItems: null,
    mainFields: ['arrivalDateFrom', 'destination', 'sortBy', '_page'],
    init() {
        this.$form = $('.search-form.search-travel');
        this.form = this.$form[0];

        if( ! this.form) {
            return;
        }

        this.$listItems = $('.list-items');
        this.isHome = this.$form.attr('data-ishome') || ! this.$listItems.length;

        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();
            this.getFormData($target);

            if(this.isHome) {

                if (this.defaults.tagCategoryId) {
                    this.params.tagCategoryId = this.defaults.tagCategoryId;
                }

                if (this.defaults.countryId) {
                    this.params.countryId = this.defaults.countryId;
                }

                if (this.defaults.destination) {
                    this.params.destination = this.defaults.destination;
                }

                this.params = list.getDataForUrl(this.params);
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                list.setUrlData(this.params, true, true);
            }
        });


        if(! this.isHome) {
            this.getParams();
            functions.setFormData(this.form, this.params);
            this.find();
        }

        this.initFilters().setDefaults();

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            $(this.form.destination).val('').trigger('change');
        });

        $('.btn-reset').on('click', e => {
            e.preventDefault();
            this.params.tagCategoryId = '';
            this.params.difficulty = '';
            $('[data-difficulty]').removeClass("active");
            $('[data-tagcategoryid]').removeClass("active");
            list.setUrlData(this.params, true);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            functions.scrollToElement(this.$listItems, 100);
            this.find();
        });
    },
    initFilters() {
        let tagCategoryIds = this.params.tagCategoryId ? this.params.tagCategoryId.split(',') : [];
        $.each(tagCategoryIds, function (i, tagCategoryId) {
            $('a[data-tagcategoryid="'+tagCategoryId+'"]').addClass('active');
        });

        if (this.params.difficulty) {
            $('[data-difficulty="'+this.params.difficulty+'"]').addClass('selected');
            $('[data-difficulty]').slice(0,this.params.difficulty).addClass("active");
        }

        $('a[data-tagcategoryid]').on('click', e => {
            e.preventDefault();
            const $target = $(e.target);
            this.params._page = 1;

            if ( ! $target.hasClass('active')) $target.addClass('active');
            else $target.removeClass('active');

            this.getTagCategoryId();

            list.setUrlData(this.params, true);
        });

        const $dataDifficulty = $('[data-difficulty]');
        $dataDifficulty.on('click', e => {
            e.preventDefault();
            this.params._page = 1;
            const $target = $(e.target);

            if ( ! $target.hasClass('selected')) {
                $target.addClass('selected');
                $dataDifficulty.removeClass("active");
                $dataDifficulty.slice(0,$target.attr('data-difficulty')).addClass("active");
                this.params.difficulty = $target.attr('data-difficulty');
            }
            else {
                $dataDifficulty.removeClass('active').removeClass('selected');
                this.params.difficulty = null;
            }

            list.setUrlData(this.params, true);
        });

        return this;
    },
    getFormData($form) {
        this.params = functions.getFormData($form);

        if(! this.isHome) {
            this.getTagCategoryId();
            this.getDifficultyId();
        }

        this.setDateTo();
    },
    setDefaults() {
        this.defaults.objectGroupId = this.$form.attr('data-objectgroupid');
        this.defaults.countryId = this.defaults.destination = this.$form.attr('data-countryid');
        this.defaults.tagCategoryId = this.$form.attr('data-tagcategoryid');
        this.defaults.hasDiscount = this.$form.attr('data-hasdiscount');
    },
    getParams() {
        this.params = list.getParams(this.params, {});
        this.setDateTo();
    },
    setDateTo() {

        const zeroPad = (num, places) => String(num).padStart(places, '0');

        if(this.params.arrivalDateFrom) {

            let dateFrom = new Date(this.params.arrivalDateFrom);
            let month = dateFrom.getMonth() + 1;

            let lastDateOfMonth = new Date(dateFrom.getFullYear(), month, 0);
            this.params.arrivalDateTo = lastDateOfMonth.getFullYear() + '-' + zeroPad(lastDateOfMonth.getMonth() + 1,2) + '-' + zeroPad(lastDateOfMonth.getDate(),2);
        }
    },
    getTagCategoryId() {
        let tagCategoryIds = [];
        $('[data-tagcategoryid]').each((index, target) => {
            const $target = $(target);
            if($target.hasClass('active')) {
                tagCategoryIds.push($target.attr('data-tagcategoryid'));
            }
        });
        this.params.tagCategoryId = tagCategoryIds.join(",");
    },
    getDifficultyId() {
        this.params.difficulty = $('.difficulty-circle.selected').attr('data-difficulty');
    },
    find() {
        list.query = $.extend({}, this.defaults, this.params);
        list.params = this.params;
        list.find().then((response) => {
            list.findDone(response);
            // LOZAD
            window.observer.observe();
        });
    },

};